"use client";
import React from 'react';
import { useRouter } from 'next/navigation';
import Image from 'next/image';
import { AppWrapper } from '@/components/layouts/AppWrapper';
import { Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export default function NotFound() {
  const router = useRouter();

  return (
    <AppWrapper>
      <div className="404-page flex flex-col items-center justify-center h-full w-full">
        <div className="thumb">
          <Image
            src="/images/404.avif"
            alt="404"
            width={400}
            height={400}
          />
        </div>
        <Button 
          type="button"
          variant="outlined"
          className="btn btn-primary normal-case mt-4" 
          onClick={() => router.push('/home')}
          title="Voltar para a página inicial"
        >
          <ChevronLeftIcon className="mr-1" /> Voltar para a página inicial
        </Button>
      </div>
    </AppWrapper>
  );
}
